"use client";

import React, { useEffect, useState } from "react";

/* Components */
import { Select } from "@components";

/* Services */
import { useCourseSearch } from "@src/service/course";
import { useRouter } from "next/navigation";

type DropdownOption = {
  label: string;
  value: string;
};

type Props = {
  children?: React.ReactNode;
};

export const HeroSearch: React.FC<Props> = (props) => {
  const {} = props;

  const router = useRouter();

  const [query, setSearch] = useCourseSearch();

  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    if (query.data?.data)
      setOptions(
        query.data.data.map((course) => ({
          label: course.name,
          value: course.slug,
        })),
      );
  }, [query.data]);

  /* Handler */
  const onValueChange = (value: DropdownOption) => {
    router.push(`/course/${value.value}`);
  };

  return (
    <div className="w-full lg:w-1/2">
      <Select
        onChange={onValueChange}
        placeholder="Search for a course..."
        onSearch={(val) => setSearch(val)}
        name="hero-search"
        isLoading={query.isFetching}
        isSearchable
        options={options}
        isDynamicOptions
      />
    </div>
  );
};
function DropdownOption(arg0: never[]) {
  throw new Error("Function not implemented.");
}
