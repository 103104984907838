"use client";
import React from "react";
import Image from "next/image";

/* Components */
import { Heading, Text, SuspenseSkeleton, CircleLight } from "@components";
import { containerStyles, shadowStyles } from "./component.css";
import { useDark } from "@stores";
import { Player } from "@lottiefiles/react-lottie-player";

type Props = {
  children?: React.ReactNode;
  title: string;
  description: string;
  animationData: any;
  direction?: "left" | "right";
  hasShadow?: boolean;
  playerClasses?: string;
};

export const KeyconceptsComponent: React.FC<Props> = (props) => {
  const {
    title,
    description,
    animationData,
    direction = "left",
    hasShadow,
    playerClasses = "",
  } = props;

  const { isDark } = useDark();

  return (
    <div
      className={`lg:overflow-visible overflow-clip relative lg:mb-32 ${
        hasShadow && isDark ? shadowStyles : ""
      }`}
    >
      <CircleLight top="50%" left="70%" size="12rem" intensity={100} />
      <CircleLight top="50%" left="20%" size="8rem" intensity={75} />
      <div className={`container ${containerStyles[direction]}`}>
        <div className="flex flex-col lg:w-1/2 w-full gap-6">
          <div className="lg:text-left text-center">
            <Heading weight={700} size="2xl">
              Important Key Concepts of
              <Text size="2xl" as="span" weight={700} color="gradient">
                {" "}
                {title}
              </Text>
            </Heading>
          </div>
          {/* <div>
            <Text color="blue" textCase="uppercase" size="lg">
              {data?.subheading}
            </Text>
          </div> */}
          <div>
            <SuspenseSkeleton width="100%" height={100}>
              <Text>{description}</Text>
            </SuspenseSkeleton>
          </div>
          {/* <Button
            variant="outline"
            color="gradient"
            size="xl"
            rightIcon={() => <ArrowUpRightIcon width={18} height={18} />}
          >
            <Link href={data?.hrefUrl || "#"}>Read More</Link>
          </Button> */}
        </div>
        <div>
          <Player
            className={`w-96 aspect-square ${playerClasses}`}
            src={animationData}
            autoplay
            loop
          />
        </div>
      </div>
    </div>
  );
};
