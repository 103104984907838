"use client";

import Link from "next/link";
import Image from "next/image";
import React from "react";

/* Components */
import { HeroSearch } from "./search";
import { Astronaut, FlyingSaucer, Rocket } from "./illustrations";
import { CircleLight, Container, Heading, Text } from "@components";

/* Types */
import { useWindowSize } from "@src/hooks";

type Props = {
  children?: React.ReactNode;
};

const HomeHeroSection: React.FC<Props> = (props) => {
  const {} = props;

  /* Hooks */
  const { bp, sizes } = useWindowSize();

  return (
    <div className="relative overflow-hidden">
      <CircleLight left="-10rem" top="20%" size="20rem" />
      <CircleLight left="100%" top="20%" size="12rem" />
      <div className="container mt-24">
        <div className="text-center flex flex-col gap-12 w-full lg:w-2/3 mx-auto">
          <Heading size="3xl" weight={700}>
            Code your
            <Text size="3xl" weight={700} as="span" color="gradient">
              {" Dreams "}
            </Text>
            into
            <Text size="3xl" weight={700} as="span" color="gradient">
              {" Reality."}
            </Text>
          </Heading>
          <div className="flex flex-col items-center justify-center gap-4">
            <Text>Start by searching for a course below!</Text>
            <HeroSearch />
          </div>
        </div>
        <div className="grid grid-rows-4 lg:grid-rows-1 lg:grid-cols-4 gap-12 mx-auto mt-12 w-4/5">
          <Link href="/courses">
            <Container
              role="button"
              tabIndex={0}
              className="border-transparent border hover:border-gradient"
            >
              <Image
                alt="Learning Icon"
                src="/home/learningIcon.png"
                width={24}
                height={24}
              />
              <Text size="lg">Learning</Text>
            </Container>
          </Link>
          <Link href="/projects">
            <Container
              role="button"
              tabIndex={0}
              className="border-transparent border hover:border-gradient"
            >
              <Image
                alt="Deal Icon"
                src="/home/paperIcon.png"
                width={24}
                height={24}
              />
              <Text size="lg">Projects</Text>
            </Container>
          </Link>
          <Link href="services">
            <Container
              role="button"
              tabIndex={0}
              className="border-transparent border hover:border-gradient"
            >
              <Image
                alt="Wrench inside a gear Icon"
                src="/home/gearWrenchIcon.png"
                width={24}
                height={24}
              />
              <Text size="lg">Services</Text>
            </Container>
          </Link>
          <Link href="/pricing">
            <Container
              role="button"
              tabIndex={0}
              className="border-transparent border hover:border-gradient"
            >
              <Image
                alt="Price Tag Icon"
                src="/home/priceTagIcon.png"
                width={24}
                height={24}
              />
              <Text size="lg">Pricing</Text>
            </Container>
          </Link>
        </div>
      </div>
      {sizes.width > bp.lg && <Astronaut />}
      <div className="relative mt-24 h-[48vw] w-full">
        <Image
          priority
          loading="eager"
          alt="Red Moon"
          src="/home/moon.png"
          fill
        />

        {sizes.width > bp.lg && <FlyingSaucer />}
        {sizes.width > bp.lg && <Rocket />}
      </div>
    </div>
  );
};

export default HomeHeroSection;
