"use client";

import React, { useEffect, useMemo, useState } from "react";
import { HelpCircle, MailIcon, MapPinIcon, PhoneIcon } from "lucide-react";

/* Components */
import { ContactForm } from "@layouts/common/footer/form";
import { Modal } from "@components/Modal";
import { Button, Heading, Text } from "@components";

/* Data */
import * as footerData from "../../common/footer/data.json";

/* Type */

type Props = {
  children?: React.ReactNode;
};

type DataWrapperProps = {
  children?: React.ReactNode;
  icon: "map" | "phone" | "email";
};

const HomeFAB: React.FC<Props> = (props) => {
  const {} = props;

  const [open, setOpen] = useState(false);

  return (
    <Modal
      setModalOpen={setOpen}
      style={{ "--z-index": "0" }}
      wrapperClasses="mt-12 lg:mt-0"
      width="60%"
      className="border-gradient bg-gradient !rounded-3xl p-12"
      triggerClasses="fixed top-[25%] right-0"
      TriggerComponent={() => (
        <Text className="w-max bg-primary px-4 py-2 rounded-l-md">
          Contact Us
        </Text>
      )}
      isModalOpen={open}
    >
      <div className="flex w-full">
        <div className="flex items-center justify-center flex-col gap-12 w-1/2">
          <DataWrapper icon="map">
            <Text>{footerData.address}</Text>
          </DataWrapper>
          <DataWrapper icon="phone">
            <Text>
              {footerData.phone.map((phone, i) => (
                <Text key={i + phone} className="pl-4">
                  {phone}
                </Text>
              ))}
            </Text>
          </DataWrapper>
          <DataWrapper icon="email">
            <Text>{footerData.email}</Text>
          </DataWrapper>
        </div>
        <div className="flex w-1/2 flex-col gap-4">
          <Heading size="xl">Contact Us</Heading>
          <div className="flex w-full">
            <ContactForm onSubmit={() => setOpen(false)} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const DataWrapper: React.FC<DataWrapperProps> = (props) => {
  const Icon = useMemo(() => {
    switch (props.icon) {
      case "email":
        return MailIcon;
      case "phone":
        return PhoneIcon;
      default:
        return MapPinIcon;
    }
  }, [props.icon]);

  return (
    <div className="flex gap-2">
      <Icon size={24} color="currentColor" className="text-primary-text" />
      <div>{props.children}</div>
    </div>
  );
};

export default HomeFAB;
