import React from "react";
import Link from "next/link";
import Image from "next/image";

/* Components */
import { Card, Heading } from "@components";

/* Types */
import type { ListByRankType } from "@src/service/course";
import { ChevronRightIcon } from "lucide-react";

type Props = {
  children?: React.ReactNode;
  course: ListByRankType;
};

export const HomeCourseCard: React.FC<Props> = (props) => {
  const { course } = props;
  return (
    <Card style={{ width: "75%" }} rows="2fr 1fr" className="h-80 !mx-auto">
      <div className="relative w-full min-h-[6rem]">
        <Image
          priority={false}
          src={course.thumbnailUrl}
          fill
          alt="Image"
          className="object-cover"
        />
      </div>
      <div className="flex flex-col gap-4 mt-4 pb-4">
        <Link
          role="link"
          className="cursor-pointer"
          href={"/course/" + course.slug}
        >
          <Heading className="text-center" weight={700}>
            {course.name}
          </Heading>
        </Link>
      </div>
    </Card>
  );
};

export const ViewAllCourses = () => {
  return (
    <Link href="/courses">
      <Card
        style={{ width: "75%" }}
        rows="1fr"
        className="h-80 !mx-auto place-items-center"
      >
        <button className="flex items-center justify-center flex-col gap-2">
          <Heading>View All Courses</Heading>
          <ChevronRightIcon
            size={32}
            className="text-primary-text hover:translate-x-3 transition-transform duration-300"
          />
        </button>
      </Card>
    </Link>
  );
};
