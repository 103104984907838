"use client";

import React from "react";
import Slider from "react-slick";

/* Components */
import { HomeCourseCard, ViewAllCourses } from "./component";

/* Hooks */
import { useUniqueId, useWindowSize } from "@src/hooks";

/* APIs */
import { useListCourseByRank } from "@src/service/course";
import { ChevronLeftIcon, ChevronRight, ChevronRightIcon } from "lucide-react";

type Props = {
  children?: React.ReactNode;
};

const CourseCarousel: React.FC<Props> = (props) => {
  /* Hooks */
  const [res] = useListCourseByRank(false);
  const { bp } = useWindowSize();

  /* Memos */
  const cardKey = useUniqueId("home-course-carousel-card-");

  return (
    <div className="container">
      <Slider
        slidesToShow={5}
        slidesToScroll={1}
        initialSlide={0}
        autoplay
        infinite={false}
        autoplaySpeed={2000}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        responsive={[
          {
            breakpoint: 1280,
            settings: { slidesToShow: 4 },
          },
          {
            breakpoint: 1024,
            settings: { slidesToShow: 3 },
          },
          {
            breakpoint: 640,
            settings: { slidesToShow: 2 },
          },
          {
            breakpoint: 480,
            settings: { slidesToShow: 1 },
          },
        ]}
      >
        {res.data.data!.map((item, i) => (
          <HomeCourseCard key={cardKey + i} course={item} />
        ))}
        <ViewAllCourses />
      </Slider>
    </div>
  );
};

function NextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <ChevronRightIcon
      className={`${className} !h-10 !w-10 !text-white`}
      color="currentColor"
      size={48}
      onClick={onClick}
    />
  );
}

function PrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <ChevronLeftIcon
      className={`${className} !h-10 !w-10 !text-white`}
      color="currentColor"
      size={48}
      onClick={onClick}
    />
  );
}

export default CourseCarousel;
