"use client";

import React from "react";
import Link from "next/link";

/* Components */
import { Heading } from "@components";
import {
  VercelLogo,
  IBMLogo,
  AWSLogo,
  DotcomLogo,
  CareerSoftSolutionsLogo,
} from "@src/assets/partners";

type Props = {
  children?: React.ReactNode;
};

const HomePartners: React.FC<Props> = (props) => {
  const {} = props;
  return (
    <div className="py-8 flex justify-center flex-col items-center gap-12 px-2 bg-primary my-16 z-50">
      <Heading size="base" color="white">
        Trusted Partners
      </Heading>
      <div className="overflow-scroll md:overflow-hidden container flex items-center justify-evenly">
        <Link target="_blank" href="https://aws.amazon.com/">
          <AWSLogo
            height={64}
            className="transition-all duration-300 grayscale hover:grayscale-0"
          />
        </Link>
        <Link target="_blank" href="https://vercel.com">
          <VercelLogo
            height={48}
            className="transition-colors duration-300 text-gray-400 hover:text-white"
          />
        </Link>
        <Link target="_blank" href="https://www.ibm.com/us-en">
          <IBMLogo
            height={64}
            className="transition-all duration-300 text-gray-400 hover:text-[#1F70C1]"
          />
        </Link>
        <Link target="_blank" href="https://www.dotcom-team.com/">
          <DotcomLogo
            height={64}
            className="transition-all duration-300 grayscale text-gray-400 hover:grayscale-0 hover:text-black"
          />
        </Link>
        <Link target="_blank" href="https://careersoftsolutions.com/">
          <CareerSoftSolutionsLogo
            height={64}
            className="transition-all duration-300 grayscale hover:grayscale-0"
          />
        </Link>
      </div>
    </div>
  );
};

export default HomePartners;
