import { AxiosWrapper } from "../axios";
import { ENDPOINTS } from "../endpoints";

import type { ContactPayload, HomeDataResponse } from "./types";

export const getHomeData = async (): Promise<HomeDataResponse> => {
  return await AxiosWrapper({
    method: "GET",
    url: ENDPOINTS.home,
    defaultHeaders: true,
  });
};

export const addEnquiry = async (data: ContactPayload) => {
  return await AxiosWrapper({
    method: "POST",
    url: ENDPOINTS.enquiryAdd,
    body: data,
    defaultHeaders: true,
  });
};
