"use client";

import React from "react";
import { KeyconceptsComponent } from "./components";

/* Animations */
import * as aiAnimationData from "@src/assets/animations/ai-home.json";
import * as dataEngAnimationData from "@src/assets/animations/dataeng-home.json";

type Props = {
  children?: React.ReactNode;
};

export const MLKeyConcepts: React.FC<Props> = (props) => {
  const {} = props;

  return (
    <KeyconceptsComponent
      hasShadow
      title="Machine Learning"
      animationData={aiAnimationData}
      description="Machine learning is a method of data analysis that automates analytical model building. It is a branch of artificial intelligence based on the idea that systems can learn from data, identify patterns and make decisions with minimal human intervention."
    />
  );
};

export const DataEngKeyConcepts: React.FC<Props> = (props) => {
  const {} = props;

  return (
    <KeyconceptsComponent
      direction="right"
      title="Data Engineering"
      playerClasses="w-[28rem]"
      animationData={dataEngAnimationData}
      description="Data engineering is a term that is quite popular in the field of Big Data and it mainly refers to Data Infrastructure or Data Architecture. Data engineering is the aspect of data science that focuses on practical applications of data collection and analysis."
    />
  );
};
